import React, { useState, useEffect, useRef } from 'react';
import { userAppData } from '../MainApp.js';
import './OncallDesktop.css';
import './OncallMobile.css';
import LoadingComp from '../common/loading.js';
import { ApiError } from '../common/apiError.js';
import { useNavigate } from "react-router-dom";
import { SEC_ApiCall, SEC_DataApiPost, sortArrayByNumericalProperty, sortArrayByProperty } from '../func.js';
import { OnCallActiveCall } from './OnCallActiveCall.js';
import { OnCallPendingCall } from './OnCallPendingCall.js';
import { MdNotificationAdd } from 'react-icons/md';
import { OnCallAddNewCallFormModal } from '../common/modals.js';
import onCallPendingCall from './onCallPendingCall.mp3';
import { set } from 'date-fns';



export default function OncallHome() {
    const [loggableUsersState, setLoggableUsersState] = useState([]);
    const [newCallViewState, setNewCallViewState] = useState(false);
    const [callTypesState, setCallTypesState] = useState([]);

    const [dataState, setDataState] = useState([]);
    const [dataCallsState, setDataCallsState] = useState([]);
    // call count states
    const [totalCallCountState, setTotalCallCountState] = useState(null);
    const [pendingCallCountState, setPendingCallCountState] = useState(null);
    const [activeCallCountState, setActiveCallCountState] = useState(null);
    // states for active and pending calls
    const [activeCallsState, setActiveCallsState] = useState([]);
    const [pendingCallsState, setPendingCallsState] = useState([]);

    const [dataPupilsState, setDataPupilsState] = useState([]);
    const [dataSltState, setDataSltState] = useState([]);
    const [isLoadedState, setIsLoadedState] = useState(false);
    const [apiErrorState, setApiErrorState] = useState(false);
    const [apiErrorTextState, setApiErrorTextState] = useState('');
    const [postApiErrorState, setPostApiErrorState] = useState(false);

    // modal appearence state
    const [modalActiveState, setModalActiveState] = useState(false);

    // sound effects
    const [play, setPlay] = useState(false); // use to play new notification sounds
    const audioRef = useRef(null); // Create a ref for the audio element

    async function apiCall(params) { // check that all this data is needed for every user? Seems like the slt isnt needed for standard users
        var url = 'oncall';
        try {
            const response = await SEC_ApiCall(url, params, setApiErrorState);
            setDataState(response);
            setDataCallsState(response["data"]);
            if (response["slt"]) {
                let sortedSlt = sortArrayByProperty(response['slt'], 'displayName', 'ascending');
                setDataSltState(sortedSlt);
            };
            setCallTypesState(response["callTypes"]);

            // loop through calls and add each one to correct states
            // Separate the calls into active and pending based on `isAcknowledged`
            const activeCalls = response["data"].filter(call => call.isAcknowledged);
            const pendingCalls = response["data"].filter(call => !call.isAcknowledged);
 
            // sort arrays into numerical order by submissionEpoch
            const activeCallsSorted = sortArrayByNumericalProperty(activeCalls, 'submissionEpoch', 'decending');
            const pendingCallsSorted = sortArrayByNumericalProperty(pendingCalls, 'submissionEpoch', 'decending');
            // Update state
            setActiveCallsState(activeCallsSorted);
            setPendingCallsState(pendingCallsSorted);

            setIsLoadedState(true);
            setTotalCallCountState(response["data"].length);
            setPendingCallCountState(pendingCalls.length);
            setActiveCallCountState(activeCalls.length);
            
            if (pendingCalls.length > 0  && userAppData['onCall']['allowManageCalls']) {
                setPlay(true);
            } else {
                setPlay(false);
            }
        } catch (e) {
            console.log(e);
            setIsLoadedState(true);
            setApiErrorState(true);
            setApiErrorTextState(e);
        }
    };


    useEffect(() => {

        var params = {};
        apiCall(params);

        const audio = document.getElementById('audio');
        let timer;

        if (pendingCallCountState && userAppData['onCall']['allowManageCalls']) {
            setPlay(true);
        } else {
            setPlay(false);
        }

        const playAudio = () => {
            // Reset audio to start to ensure it plays from the beginning
            audio.currentTime = 0;
            audio.play().catch(e => console.error("Error playing audio:", e));
        };

        const handleAudioEnd = () => {
            // Wait for the remainder of the 5 seconds after audio ends, then play again
            const timeLeft = 5000 - audio.duration * 1000;
            timer = setTimeout(playAudio, timeLeft > 0 ? timeLeft : 0);
        };

        if (play) {
            playAudio();
            audioRef.current.addEventListener('ended', handleAudioEnd);
        } else {
            clearTimeout(timer);
            if (audioRef.current) {
                audioRef.current.pause();
            }
        }

        ////    API Call every 20 seconds

        const apiTimer = setInterval(() => {
            apiCall(params);
        }, 30000);

        ////    Delete setInterval timer on component dismount
        return () => {
            clearInterval(apiTimer);
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleAudioEnd);
            }
            clearTimeout(timer);
        }
    }, [play]);


    function newCallButtonClicked() {
        if (modalActiveState) {
            setModalActiveState(false);
        } else {
            setModalActiveState(true);
        }
    }

    function modalClose() {
        var params = {};
        apiCall(params);
        setModalActiveState(false);
    };

    if (!isLoadedState) {
        return (
            <div className='main_div'>
                <LoadingComp />
            </div>
        )
    } else if (apiErrorState) {
        return (
            <ApiError />
        )
    } else {
        return (
            <div className='main_div'>
                <div className='internal_main_div'>
                    <div className='oncall_header_div'>
                        <h1 className='admin_header' >OnCall</h1>
                        {<div className='oncall_new_call_buton' onClick={() => newCallButtonClicked()}><MdNotificationAdd color='#ffffff' size='40px' /></div>}
                        <OnCallAddNewCallFormModal modalActiveState={modalActiveState} setStateFunction={setModalActiveState} onModalClose={modalClose} />
                    </div>
                    <div className='oncall_calls_main_div'>


                        <div className='oncall_calls_pending_calls_outer_div'>
                            <h1 className='onCallState_header'>Pending Calls ({pendingCallCountState})</h1>
                            <div className='oncall_calls_pending_calls_div'>
                                <audio ref={audioRef} id="audio" src={onCallPendingCall}></audio>
                                {pendingCallsState.map((item) =>
                                    <OnCallPendingCall
                                        key={item["id"]}
                                        data={item}
                                        activeCallsState={activeCallsState}
                                        setActiveCallsState={setActiveCallsState}
                                        pendingCallsState={pendingCallsState}
                                        setPendingCallsState={setPendingCallsState}
                                        apiCall={apiCall}
                                    />
                                )
                                }
                            </div>
                        </div>


                        <div className='oncall_calls_pending_calls_outer_div'>
                            <h1 className='onCallState_header'>Active Calls ({activeCallCountState})</h1>
                            <div className='oncall_calls_active_calls_div'>

                                {activeCallsState.map((item) =>
                                    <OnCallActiveCall
                                        key={item["id"] + item["isComplete"] + item["assignedUser"] + item["isAcknowledged"] + item["notes"]}
                                        data={item}
                                        slt={dataSltState}
                                        activeCallsState={activeCallsState}
                                        setActiveCallsState={setActiveCallsState}
                                        callTypes={callTypesState}
                                        apiCall={apiCall}
                                    />
                                )
                                }
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        )
    }
};